// Lightbox
@import "~ngx-lightbox/lightbox.css";

// Select 2
@import "~@ng-select/ng-select/themes/default.theme.css";

// Date Picker
@import "~flatpickr/dist/flatpickr.css";

// LeafLet Chart
@import "leaflet/dist/leaflet.css";

// Emoji Picker
@import "@ctrl/ngx-emoji-mart/picker";

.emoji-mart {
  position: absolute;
  bottom: 20px;
  left: -80px;
}
.emoji-mart-preview {
  display: none;
}

// dropdown
.dropdown-toggle {
  &:after {
    display: none;
  }
}

.modal-backdrop {
  z-index: 1002 !important;
}

.dropdown-toggle::after {
  display: none;
}

.dropzone .dz-message {
  font-size: 24px;
  min-height: 150px !important;
  border: none !important;
  background: #fff !important;
  color: #495057 !important;
}

// ----- Advance Ui --------//
// Rating
.stars {
  font-size: 30px;
  color: var(--bs-gray-600);
}
.star {
  position: relative;
  display: inline-block;
}
.star_fill {
  color: $gray-500;
}
.full {
  color: #b0c4de;
}
.half {
  position: absolute;
  display: inline-block;
  overflow: hidden;
  color: var(--bs-gray-600);
}
.filled {
  color: #1e90ff;
}
.bad {
  color: #deb0b0;
}
.filled.bad {
  color: #ff1e1e;
}
.halfHeart {
  position: absolute;
  display: inline-block;
  overflow: hidden;
  color: red;
}

// Calendar
.flatpickr-calendar.open {
  z-index: 1061;
}

// Swich Btn
.switch.switch-small.checked > .switch-pane > span {
  color: #fff;
}
.switch.switch-small > .switch-pane > span {
  font-size: 14px;
}

.custom-accordion {
  .accordion-list {
    padding: 0px;
    background-color: transparent;
  }
}

body {
  margin: 0;
  padding: 0;
  font-size: 13px;
  color: #3a3a3a;
  font-weight: 400;
}

body.ar {
  font-family: "Cairo", "sans-serif" !important;
}

body.en {
  font-family: "Poppins", "sans-serif" !important;
}

* {
  margin: 0px;
  padding: 0px;
}

.clearfix {
  *zoom: 1;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
  line-height: 0;
}

.clearfix:after {
  clear: both;
}

.clr,
.clear {
  clear: both;
}

header,
footer,
aside,
article,
section,
detail,
nav,
figure {
  position: relative;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

ul,
li,
ol {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  outline: none;
  cursor: pointer;
}

a:hover,
a:focus {
  text-decoration: none !important;
  outline: none;
}

img {
  border: 0;
  outline: none;
  max-width: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Manrope", sans-serif !important;
  font-size: 13px;
  color: #3a3a3a;
  font-weight: 400;
}

* {
  margin: 0px;
  padding: 0px;
}

.clearfix {
  *zoom: 1;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
  line-height: 0;
}

.clearfix:after {
  clear: both;
}

.clr,
.clear {
  clear: both;
}

header,
footer,
aside,
article,
section,
nav,
figure {
  position: relative;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

ul,
li,
ol {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  outline: none;
  cursor: pointer;
}

a:hover,
a:focus {
  text-decoration: none !important;
  outline: none;
}

img {
  border: 0;
  outline: none;
  max-width: 100%;
}

input[type="text"],
input[type="password"],
select,
textarea,
input[type="button"] {
  max-width: 100%;
}

table {
  width: 100%;
}
p {
  margin: 0;
  padding: 0;
}
.fltL {
  float: left;
}
.fltR {
  float: right;
}
.padding_none {
  padding: 0 !important;
}
.border_none {
  border: none !important;
}
.navbar-toggle {
  background-color: #fff !important;
}
.icon-bar {
  background-color: #000 !important;
}
.p_right0 {
  padding-right: 0px !important;
}
.p_left0 {
  padding-left: 0px !important;
}
.m_bottom0 {
  margin-bottom: 0 !important;
}

input[type="date"] {
  text-transform: uppercase !important;
}

.Green {
  color: #37a713;
}
.Red {
  color: #e21818;
}
.Blue {
  color: #139bbf;
}
.Yellow {
  color: #edbd08;
}
.Pink {
  color: #fd546d;
}
.Orange {
  color: #e99c3d;
}

.Button {
  border: none;
  padding: 10px 30px;
  text-align: center;
  transition: 0.5s all ease-in-out;
  font-weight: 700;
  display: inline-block;
  position: relative;
  background-color: #000000;
  font-size: 15px;
  color: #fff;
  text-transform: capitalize;
  border-radius: 7px;
  outline: 0;
  box-shadow: none;
}
.Button:hover {
  background-position: 0px 80px;
  color: #fff;
}
.Button.Cancel {
  background: #d9d9d9;
  color: #000;
  margin: 0 0 0 0;
  padding: 10px 20px;
}

.Switch {
  position: relative;
  display: inline-block;
  cursor: pointer;
  margin: 0;
  padding: 2px 0 3px 50px;
  font-weight: 400;
  font-size: 12px;
  color: #646467;
  float: right;
}
.Switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.Switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  height: 21px;
  border-radius: 50px;
  width: 40px;
  border: 1px solid #000000;
  min-width: auto;
}
.Switch .slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 3px;
  bottom: 0;
  top: 0;
  background-color: #000000;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
  margin: auto;
}
.Switch input:checked + .slider {
  background-color: #000000;
}
.Switch input:checked + .slider:before {
  -webkit-transform: translateX(19px);
  transform: translateX(19px);
  background: #fff;
}

.CheckBox {
  display: inline-block;
  position: relative;
  padding-left: 33px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-weight: 600 !important;
  color: #2d2d2d;
  margin: 0 !important;
  min-height: 20px;
}
.CheckBox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.CheckBox .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 24px;
  background-color: transparent;
  border: 1px solid #00a6b6;
  border-radius: 2px;
  min-width: auto;
}
.CheckBox:hover input ~ .checkmark {
  background: #00a6b6;
  border-color: #00a6b6;
}
.CheckBox input:checked ~ .checkmark {
  background: #00a6b6;
}
.CheckBox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 8px;
  top: 2px;
  width: 5px;
  height: 11px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.CheckBox input:checked ~ .checkmark:after {
  display: block;
}

.Radio {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin: 0 !important;
}
.Radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.Radio .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid #000;
}
.Radio:hover input ~ .checkmark {
  border-color: #000;
  background: #000;
}
.Radio input:checked ~ .checkmark {
  border-color: #000;
  background: #fff;
}
.Radio .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.Radio input:checked ~ .checkmark:after {
  display: block;
}
.Radio .checkmark:after {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #000;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
}

.Overflow {
  overflow: hidden;
}

.CloseModal {
  position: absolute;
  width: 30px;
  height: 30px;
  background-color: #fff;
  border: 2px solid #000000;
  text-align: center;
  color: #000;
  font-size: 18px;
  border-radius: 6px;
  top: 25px;
  right: 25px;
  font-weight: 800;
  line-height: 25px;
}

.ng-select .ng-dropdown-panel .ng-option.ng-option-marked {
  background-color: #000 !important;
  color: #fff !important;
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --bs-gutter-x: 1.5rem;
}

.WrapperBoxChat {
  height: calc(100vh - 163px) !important;
}
.WrapperArea {
  background-color: #fbfafc;
  margin-left: 235px;
  flex: 1 1 auto;
  width: calc(100% - 235px);
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  transition: 0.5s all ease-in-out;
}
.WrapperArea .WrapperBox {
  background-color: #fafbfc;
  min-height: calc(100vh - 60px);
  position: relative;
}
.Small-Wrapper {
  background-color: #fbfafc;
  padding: 25px 25px 75px 25px;
}
.Small-Wrapper + .Small-Wrapper {
  margin-top: 20px;
}

.salesrefundArea .sfaBox h6 {
  font-weight: 600;
  font-size: 18px;
  color: #000000;
  margin: 0 0 30px 0;
}
.salesrefundArea .sfaBox p {
  font-weight: 400;
  color: #000000;
  font-size: 16px;
  margin-bottom: 25px;
}
.salesrefundArea .sfaBox ul li {
  font-weight: 400;
  color: #000000;
  font-size: 16px;
  list-style-type: initial;
}
.salesrefundArea .sfaBox {
  margin-bottom: 40px;
}
.salesrefundArea .sfaBox label {
  font-size: 20px;
  color: #000000;
  font-weight: 600;
  margin-bottom: 15px;
}
.salesrefundArea .sfaBox form .form-group .addressForm {
  height: 77px;
}
.salesrefundArea .sfaBox form .form-control {
  border-radius: 15px;
  height: 52px;
  border: 1px solid #ebeaed;
}
.salesrefundArea .sfaBox form .form-control:focus {
  box-shadow: none;
  outline: none;
}

.form-check-input:checked {
  background-color: #000;
  border-color: #000;
}

.page-link.active,
.active > .page-link {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: #000;
  border-color: none;
}

td,
th {
  text-align: center;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: #f3f3f3;
  color: #000000;
  vertical-align: middle;
}
.table-striped > tbody > tr > * {
  padding: 1.5rem 0.75rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
  font-weight: 600;
  font-size: 0.9rem;
  vertical-align: middle;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
.ng-dropdown-panel
  .ng-dropdown-panel-items
  .ng-option.ng-option-selected.ng-option-marked,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  color: #000 !important;
}
.ng-select .ng-dropdown-panel .ng-option.ng-option-marked {
  background-color: #000000 !important;
  color: #fff !important;
}
